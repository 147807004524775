.AdaAiButton {
  padding: 7px 7px 7px 14px;
}

.AdaAi {
  background-color: #005DFF;
  padding: 0 7px 0 7px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 140px;
  justify-content: center;
}

.AdaAi i {
  margin-left: 4px;
  font-size: 23px !important;
  display: flex;
}

.AdaAiButton .AdaAi:hover {
  background-color: rgb(71, 130, 248);
}
