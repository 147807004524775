.ContainerEditActionButtons {
  align-items: safe center;
  display: flex;
  justify-content: space-between;
}

.ContainerEditActionButtons .actionButton {
  width: 100%;
}

.iconConfigWrapper {
  align-items: end;
  display: inline-flex;
  gap: 15px;
}

.iconConfigWrapper :global(.BngInputIconSelector label) {
  display: flex;
  flex-direction: column;
}

.iconConfigWrapper :global(.BngInputIconSelector.round) {
  height: 35px;
  width: 35px;
}

.iconConfigWrapper :global(.bng-button-group) {
  margin-bottom: 20px;
}

.iconConfigWrapper :global(.bng-button-group .bng-button-group-item) {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  padding: 5px;
}

.iconConfigWrapper :global(.bng-button-group i) {
  font-size: 20px;
}

.showTitleToggle {
  align-items: start;
  flex-direction: column-reverse !important;
  gap: 8px;
  margin-bottom: 18px;
}

.showTitleToggle :global(.BngSwitchLabel) {
  font-weight: 500;
}

.ContainerStyleAccordion :global(.AccordionDescription) {
  width: 100%;
}

.customStyleAccordion {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
