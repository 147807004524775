.NameIconWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.StructureNameInput {
  width: 26vh;
}

.StructureTypeTitle,
.IconSelectorWrapper,
.snapshotStructureCheckBox :global(.lbl),
.StructureNameInputLabelClassName,
.StructureDescriptionLabelClassName {
  height: fit-content;
  gap: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}

.NameIconWrapper i {
  align-items: center;
  background-color: white;
  border: 1px solid #d1d1d1;
  border-radius: 20px;
  color: #005dff;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-bottom: 18px;
  width: 35px;
}

.IconSelectorWrapper i {
  margin-bottom: 0;
}

.NameIconWrapper :global(.BngField) {
  margin-bottom: 0;
  height: fit-content;
}

.tagWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.StructureTypeTitle {
  margin-bottom: 8px;
}

.StructureType,
.StructureType:global(.selected) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  width: 145px;
  height: 38px;
  border-radius: 8px;
  background-color: white;
  position: relative;
}

.StructureType {
  border: 1px solid #d1d1d1;
}

.StructureType:global(.selected) {
  border: 1px solid #005dff;
  color: #005dff;
}

.StructureType i {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-left: 12px;
}

.StructureType i {
  color: #555555;
}

.StructureType:global(.selected) i {
  color: #005dff;
}

.StructureType div:global(.BngRadio.BngCheckbox) {
  position: absolute;
  right: 0;
}

.StructureTypeWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.GoBackButton {
  left: 1vh !important;
  background-color: #555555;
}

.NextStepButton,
.GoBackButton {
  position: absolute;
  bottom: 1vh;
  right: 18vh;
  width: 150px;
}

.NextStepButton {
  right: 1vh;
}

.checkboxWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: space-around;
}

.StructureDescriptionInput :global(.controls) :global(.cleditorMain) {
  height: 144px !important;
}
