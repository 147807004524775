.AdaAiChat:global(.bs-dialog.BngStepTutorialDialog) {
  right: 1%;
  bottom: 1%;
  left: unset;
  top: unset;
}

.AdaAiChat:global(.bs-dialog.BngStepTutorialDialog
    .modal-dialog
    .modal-content
    .modal-body
    .UiBlocker
    .widget-box
    .widget-body),
.AdaAiChat:global(.bs-dialog.BngStepTutorialDialog
    .modal-dialog
    .modal-content
    .modal-body
    .UiBlocker
    .widget-box
    .widget-body
    .widget-main) {
  padding: 0 !important;
}

.AdaAiChat:global(.bs-dialog.maximized) {
  width: unset;
  height: unset;
  margin: 0;
  left: 2%;
  top: 4%;
  bottom: 4%;
  right: 2%;
}

.AdaAiChat:global(.bs-dialog.BngStepTutorialDialog .stepContainer) {
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.AdaAiChat:global(.bs-dialog .widget-header) {
  pointer-events: none !important;
}

.AdaAiModalBackdrop {
  z-index: unset !important;
  background-color: transparent !important;
  position: unset;
}

.AdaAiModalBackdrop:global(.maximized) {
  z-index: 1040 !important;
  background-color: #c1c1c1 !important;
  position: fixed !important;
}

.AdaAiChatTitle {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AdaAiChatTitle img {
  margin-right: 8px;
}

.AdaAiChatTitleText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  max-width: 100%;
}

/******************************************************************
 Body
******************************************************************/

.AdaAiChatBodyImg {
  width: 280px;
  margin: 0 auto !important;
  display: flex;
}

.AdaAiChat :global(.stepContainer) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
}

.StepOneWrapper {
  display: flex;
  flex-direction: column;
  height: 516px;
  padding: 16px 52px 32px 52px;
  background-color: #ffffff;
}

.AdaAiChat :global(.stepContainer) h1,
.AdaAiChat :global(.stepContainer) h2,
.AdaAiChat :global(.stepContainer) h3 {
  color: #000000;
  text-align: center;
}

.AdaAiChat :global(.stepContainer) h1 {
  margin: 36px 0;
  line-height: 28.13px;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
}

.AdaAiChat :global(.stepContainer) h2 {
  font-size: 20px;
  line-height: 23.44px;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: bold;
}

.AdaAiChat :global(.stepContainer) h3 {
  font-size: 16px;
  line-height: 18.75px;
  margin-top: 0;
  margin-bottom: 24px;
}

.AdaAiChat :global(.widget-header > :first-child) {
  max-width: 350px;
}

.Question {
  text-align: left !important;
}

.AdaAiChatMessageButton {
  padding-bottom: 14px;
}

.AdaAiChatMessageInfo {
  color: #005dff;
  display: flex;
  align-items: center;
}

.AdaAiChatMessageInfoH3 {
  color: #005dff !important;
  font-weight: bold;
  text-align: left !important;
  margin: 0 0 0 10px !important;
  display: flex;
}

.AdaAiChatBodyButton {
  display: flex;
  background-color: transparent;
  padding: 5px 12px 5px 12px;
  border-radius: 12px;
  border: 1px solid black;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.AdaAiChatBodyButton img {
  margin-right: 15px !important;
  margin-left: 10px;
  display: flex;
}

.BackArrow {
  cursor: pointer;
  font-size: 24px;
  margin-right: 8px;
  color: black;
  pointer-events: auto !important;
}

.AdaAiChat :global(.animationWrapper) {
  height: 570px !important;
}

.AdaAiChat:global(.bs-dialog.maximized .animationWrapper) {
  height: 87vh !important;
}

.MessageInput {
  width: 100%;
  height: 50px !important;
  border-width: 0;
  padding: 0 20px 0 20px !important;
}

.AdaAiChat {
  height: auto;
  display: flex;
  align-items: center;
}

.AdaAiChat img {
  margin-right: 8px;
}

.AdaAiChat :global(.animatedClasName) {
  height: 564px;
  overflow-y: hidden !important;
}

.AdaAiChat :global(.modal-body) {
  overflow-y: hidden !important;
}

/******************************************************************
 AdaAiChatMessage
******************************************************************/

.BngPanelFooter {
  height: 50px;
  background-color: white;
  border-top: 1px solid #e4e4e4;
}

.AdaAiChatWrapper {
  height: 100% !important;
  width: 100%;
  background: #ffffff;
}

.AdaAiChatHistoryWrapper {
  height: 90% !important;
  width: 100%;
  background-color: white;
  overflow-y: scroll;
}

.AdaAiChat:global(.bs-dialog.maximized .AdaAiChatHistoryWrapper) {
  height: 90% !important;
}

.AdaAiChatMessage,
.AdaAiChatMessageInverter {
  display: flex;
  flex-direction: row;
  /*margin-bottom: 35px;*/
  position: relative;
  padding: 15px;
}

.AdaAiChatMessage {
  flex-direction: row;
}

.AdaAiChatMessageInverter {
  flex-direction: row-reverse;
}

.AdaAiChatMessageArrow {
  height: 12px;
  width: 12px;
  transform: rotate(315deg);
  border-radius: 2px;
  background-color: #e3e5e9;
  position: absolute;
  left: 61px;
  top: 19px;
}

.AdaAiChatMessageInverter .AdaAiChatMessageArrow {
  background-color: #005dff;
  left: unset;
  right: 61px;
}

.AdaAiChatMessageAvatar {
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border: 2px solid #ced0d5;
  border-radius: 100%;
  overflow: hidden;
}

.AdaAiChatMessageAvatar > * {
  height: 32px;
  width: 32px;
}

.AdaAiChatMessageMessage {
  background-color: #e3e5e9;
  width: 100%;
  border-radius: 4px;
  color: #292c31;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 10px 0;
  margin-left: 15px;
  word-break: break-word;
  text-align: justify;
}

.AdaAiChatMessageInverter .AdaAiChatMessageMessage {
  background-color: #005dff;
  color: #ffffff;
  margin-right: 15px;
  margin-left: 0;
  width: auto;
  padding: 15px;
}

.AdaAiChatMessageName {
  margin-bottom: 10px;
}

.FooterAdaAiChat {
  pointer-events: none;
  opacity: 0.2;
  cursor: not-allowed;
}

.AdaAiChatMessageNotMaximized {
  background-color: #e3e5e9;
  width: 85%;
  border-radius: 4px;
  color: #292c31;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 10px 0;
  margin-left: 15px;
  word-break: break-word;
  text-align: justify;
}

.AdaAiChatMessageInverter .AdaAiChatMessageNotMaximized {
  background-color: #005dff;
  color: #ffffff;
  margin-right: 15px;
  margin-left: 0;
  width: auto;
  padding: 15px;
}

/******************************************************************
 TypingDots
******************************************************************/

@keyframes typing {
  0% {
    background-color: #d9d9d9;
  }
  33% {
    background-color: #888888;
  }
  66% {
    background-color: #888888;
  }
  100% {
    background-color: #888888;
  }
}

.TypingDots span {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 2px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: typing 1.5s infinite;
}

.TypingDots span:nth-child(1) {
  animation-delay: 0s;
}

.TypingDots span:nth-child(2) {
  animation-delay: 0.5s;
}

.TypingDots span:nth-child(3) {
  animation-delay: 1s;
}
